.main-footer {
  // background: #0b0c22;
  color: #ffffff;
  background-color: #050505;
  // border: 1px solid #1e1f20;
  // border-radius: 8px;
  .mainftrdiv.my-footer-border {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .rightdocdivmain.padng_left {
      display: flex;
      align-items: center;
      display: inline-flex;
      align-items: flex-start;
      gap: 117px;
    }
  }
  .padng_left {
    // padding-left: 20px;
    text-align: start;
  }
  .main_fotrdiv {
    // background: #111213;
    border-bottom: 1px solid #313131;
    // border-radius: 8px;
    padding-top: 60px;
    padding-bottom: 36px;
  }

  .yo {
    color: #dbdbdb !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 150% !important;

    opacity: 0.8;
    color: #dbdbdb;
  }

  .headdiinnngg {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .yoyonew {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #dbdbdb !important;
    margin-top: -20px;
  }
  .yoyonewwwwwww {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #bababa;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  button.lastfotrbtn {
    background: #1777e2;
    padding: 11px 14px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff;
    &:hover {
      color: #ffffff;
      background: #0076f9;
      box-shadow: 0px 4px 9px #1777e233;
      border-radius: 6px;
      border: 1px solid #0076f9;
    }
  }

  .w200 {
    width: 200px;
  }

  // input {
  //     // background: transparent;
  //     // box-shadow: 0px 0px 16px #00000040;
  //     // border-radius: 50px;
  //     // border: 1.5px solid #818283;
  //     padding: 18.5px 8px 18.5px 15px;
  //     background: #121213;
  //     border: 1px solid #2b2c2e;
  //     border-radius: 6px;
  //     margin-bottom: 12px;

  //     &::placeholder {

  //         background: #121213;
  //         font-style: normal;
  //         font-weight: 500;
  //         font-size: 14px;
  //         line-height: 15px;

  //         color: #414141;

  //     }
  // }

  .picture {
    position: relative;

    img {
      position: absolute;
      top: 6px;
      right: 9px;
    }
  }

  // .input-group-text {
  //     background: #1fa5ff;
  //     color: #ffffff;
  //     box-shadow: 0px 0px 16px #00000040;
  //     border-radius: 50px;
  //     padding: 18.5px 8px 18.5px 15px;
  //     border: 1px solid #1fa5ff;
  //     position: absolute;
  //     right: 2px;
  //     top: 3px;
  //     // height: 45px;
  //     // width: 45px;
  // }

  .mt5 {
    margin-top: 50px;
  }

  .w28 {
    width: 28px;
  }

  li {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .inner-icon {
    p.yoyonewwwwwwww {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #bababa;
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }
  .form-control {
    background: #121213;
    border: 1px solid #2b2c2e;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #414141;
    // padding: 18.5px 8px 18.5px;
    padding: 18.5px 8px 18.5px 15px;
    margin-bottom: 12px;

    // &:hover {
    //     border: 1px solid #1777e2;
    //     border-radius: 6px;
    //     color: #ffffff;
    // }
  }
  .form-control:focus {
    background: #121213;
    border: 1px solid #2b2c2e;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #414141;
    // padding: 18.5px 8px 18.5px;
    padding: 18.5px 8px 18.5px 15px;
    &:hover {
      border: 1px solid #1777e2;
      border-radius: 6px;
      color: #ffffff;
    }
    &:active {
      border: 1px solid #1777e2;
    }
    &:focus {
      border: 1px solid #1777e2;
    }
  }
}
.col-xl-2.col-lg-2.col-md-6.col-sm-6.col-6.text_start {
  text-align: start;
}
.dignissimos {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  color: #bababa;
}

.headdiinnnggssss {
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  // text-decoration-line: underline;

  &:hover {
    color: #00c9ff;
    text-decoration-line: underline;
  }
}

.yoyonewwwwwww {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  color: #bababa;
}

.rights {
  color: #ffffff;
  // background: #111213;
  padding-top: 38px;
  // padding-bottom: 23px;
  .mainbtammdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .foterfst {
    color: #a5a5a5;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btmmrightdiv {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  p.text-white.small.cccopyrighttttts.foterfst {
    text-align: start;
  }

  p.text-white.small.cccopyrighttttts.fotersec {
    text-align: end;
    text-align: right;
  }
}

.footerhead {
  color: #fff !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

@media only screen and (max-width: 600px) {
  .inner-icon {
    text-align: start;
  }
  .rights {
    p.text-muted.small.cccopyrighttttts.foterfst {
      text-align: center !important;
    }

    p.text-muted.small.cccopyrighttttts.fotersec {
      text-align: center !important;
    }

    // background: #cf9600;
    text-align: center !important;

    .text-right {
      text-align: center !important;
    }
  }

  .main-footer {
    .headdiinnnggssss {
      margin-bottom: 15px !important;
      margin-top: 15px !important;
    }
  }

  .yoyonew {
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 24px !important;
    color: #dbdbdb !important;
  }

  .main-footer .headdiinnngg {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 27px;
    margin-bottom: 30px;
    color: #ffffff;
  }
}

@media screen and (max-width: 800px) {
  .rights p.text-white.small.cccopyrighttttts.foterfst {
    text-align: center;
  }

  .rights p.text-white.small.cccopyrighttttts.fotersec {
    text-align: center;
  }
  .col-xl-2.col-lg-2.col-md-6.col-sm-6.col-6.text_start {
    text-align: start;
  }
  .main-footer .main_fotrdiv {
    padding: 15px 15px 18px 15px;
  }
  .rights {
    color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .rights .mainbtammdiv {
    display: flex;
    justify-content: flex-start;
    align-items: self-start;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    text-align: start;
    gap: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .padng_left {
    padding-left: unset !important;
  }
}

@media (max-width: 600px) {
  .main-footer .main_fotrdiv {
    background: unset;
    border: unset;
    border-radius: unset;
  }
  .my-footer-border {
    // background: #111213;
    border-bottom: 1px solid #1e1f20;
    border-radius: 8px;
    padding: 15px 15px 18px 15px;
  }
}
