.bg-imgonly {
}

.main-banner {
  padding-top: 170px;
  overflow: hidden;
  background-image: url("../../../Assets//banerbgonly.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  .inner-contents.main-headings.text-center {
    //   background-image: url("../../../Assests/shadow.png");
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    position: relative;
    img.img-fluid.header_imgssss {
      position: absolute;
      top: -88px;
      // right: -53px;
      left: 0px;
      pointer-events: none;
    }
  }
  .tomi-pink {
    color: #1777e2;
  }

  .button-banneerr {
    transition: 0.5s ease-in-out;
    outline: none !important;
    border: none;
    border-radius: 60px;

    background: linear-gradient(90deg, #1e1cb0 0%, #00c9ff 100%);
    color: #fff;
    font-family: Tbold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    text-transform: uppercase;
    padding: 22px 31px;
    cursor: pointer;

    // margin-left: 41px;

    /* identical to box height, or 16px */

    &:hover {
      color: #ffffff;
      background: #0f1a1e;
      transition: 0.5s ease-in-out;
      // box-shadow: 0px 4px 9px #1777e233;
      // border-radius: 6px;
      // border: 1.5px solid #0076F9;
    }
  }
  .inner-content.main-heading {
    // margin-top: 147px;
    // margin-bottom: 40px;
    p.pprotocol {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-transform: uppercase;
      color: #1777e2;
      padding-bottom: 25px;
    }
    h3 {
      font-family: "Archivo", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 120%;
      text-align: center;
      color: #121212;
    }
    h2.parafirstcc {
      color: #fff;
      font-family: Tbold;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    p.parafirstff {
      color: #fff;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      // padding-top: 25px;
      // padding-bottom: 25px;
      margin-bottom: 25px;
    }
    .btn.button-hedaerss {
      background: #1777e2;
      border-radius: 6px;
      padding: 13px 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      &:hover {
        background: #0076f9;
        box-shadow: 0px 4px 9px #1777e233;
        border-radius: 6px;
      }
    }
    .btn.button-hedaersss {
      background: #111213;
      border: 1px solid #2c2c2c;
      border-radius: 6px;
      align-items: center;
      padding: 16px 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      margin-left: 12px;
      color: #1777e2;
      &:hover {
        background: #141618;
        border: 1px solid #1777e2;
        border-radius: 6px;
      }
    }
  }

  .modal-content {
    border-radius: 20px;
    .for-padding {
      padding: 0 50px;
    }
    .form-group {
      input {
        background: #ffffff;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        border-radius: 10px;
        height: 45px;
        padding: 6px 12px;
      }
    }
    .btn-common {
      width: 100%;
    }
  }
}

.counter {
  background: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 991px) {
  .main-banner .input-field {
    width: 100%;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 45px !important;
  }
}
@media only screen and (max-width: 600px) {
  .main-banner {
    .w-50 {
      width: 100% !important;
    }

    .input-group-text {
      background: #1777e2;
      color: #ffffff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      border-radius: 0px 20px 20px 0px;
      padding: 10px 10px;
    }
  }
  .main-banner .parafirst p {
    margin: unset !important;
    margin-bottom: 56px !important;
    font-size: 15px !important;
  }
}

.abb {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 600px) {
  .main-banner .input-field .btn-searchbar {
    position: unset !important;
    margin-top: 20px;
    width: 100%;
  }
  .main-banner {
    // padding-bottom: 39px;
  }
}

@media (max-width: 430px) {
  .main-banner .input-field input {
    padding-left: 50px;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 26px !important;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 24px !important;
  }
}

@media (max-width: 2500px) {
  img.img-fluid.header_img {
    margin-right: -167px;
  }
}

@media (max-width: 800px) {
  img.img-fluid.header_img {
    margin-right: unset;
    margin-top: -82px;
    margin-bottom: -100px;
  }
  .inner-content.main-heading {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    // gap: 25px;
  }
  .main-banner .inner-content.main-heading p.pprotocol {
    font-size: 14px;
    line-height: 15px;

    padding-bottom: 20px;
  }
  .main-banner .inner-content.main-heading h2.parafirstcc {
    color: #fff;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 39px */
    text-transform: uppercase;
    text-align: start;
  }
  .main-banner .inner-content.main-heading p.parafirstff {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .lastbtnbnr {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    justify-content: center;
  }
  .main-banner .inner-content.main-heading button.btn.button-hedaerss {
    padding: 12px 12px;

    font-size: 14px;
    line-height: 100%;
  }
  .main-banner .inner-content.main-heading button.btn.button-hedaersss {
    // padding: 12px 20px;

    font-size: 14px;
    line-height: 100%;
  }
  .main-banner {
    margin-top: unset;
  }
}

@media (max-width: 600px) {
  .main-banner .inner-content.main-heading .btn.button-hedaerss {
    width: 100%;
    flex: 0 0 100%;
  }
  .main-banner .inner-content.main-heading .btn.button-hedaersss {
    margin: 0 auto;
    flex: 0 0 47%;
  }
}
